.appWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
}

.myWrap {
    display: inline-block;
    position: relative;
    display: flex;
    direction: ltr;
}

.myinput {
    position: absolute;
    border: none;
    font-size: 18px;
    text-align: center;
    background-color: transparent;
    outline: none;
}

.mydisplay {
    border-bottom: 1px solid #27727a;
    width: 28px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    position: relative;
    margin-right: 6px;
    margin-left: 6px;
}

/*.myshadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28);
}*/
