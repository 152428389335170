textarea:focus, input:focus,
textarea:active, input:active,
.btn.focus, label:active, label:focus,.btn:focus
{
    outline: none!important;
    box-shadow: none!important;
}
@media (max-width: 768px){
  .h-before-768-50{
      height: 50% !important;
  }
}
#login-image {
    margin-top: 10px;
    width: 100px;
    height: auto!important;
}
#videochat-streams{
    display: flex!important;
    max-width: 100%!important;
}
.videos-wrapper{
    width: 100%;
    height: 100%;
    height: 100vh;
}
.videos-wrapper-contaciner{
    height: 100%;
}
#call, #videochat {
    background-color: #565656;
}
.videochat-stop-call {
    background: #dc3545 url(images/call_end.svg) no-repeat center;
    border:none;
    margin: 0 5px;
}
.videochat-mute-unmute {
    background: #39939b url(images/mic.svg) no-repeat center;
    border:none;
    margin: 0 5px;

}
.call-start {
    background: #39939b url(images/call.svg) no-repeat center;
    border:none;

    margin: 0 5px;
}
.videochat-switch-camera {
    background: #39939b url(images/switch_video.svg) no-repeat center;
    border:none;
    margin: 0 5px;

}
button:disabled{
    opacity: 1;
}
#call-buttons-container .call-button, #videochat-buttons-container .videochat-button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*-----------------------------patient case note-ing-----------*/
.btn-patient-case{
    direction: rtl;
    z-index: 999;
    background: #FFC107;
    box-shadow: 0 3px 6px rgb(255 193 7 / 29%), 0 1px 3px rgb(255 193 7 / 22%);
    line-height: 28px;
    border: none;
    display: flex;
    color: #fff!important;
    will-change: background;
    transition: background .3s;
    font-size: 13px;
    font-weight: 400;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.btn-patient-case i{
    font-size: 25px;
}
#pateint-case-wrapper {
    direction: rtl;
    position: fixed;
    display: none;
    bottom: 90px;
    left: 20px;
    background-color: #e7e7e7;
    width: 400px;
    max-width: calc(100% - 40px );
    border-radius: 10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    z-index: 99;
    border: 1px solid #c7c7c7;
    border-bottom-left-radius: 0;
}
@media (min-width: 1200px) {
    #pateint-case-wrapper{
        bottom: 25px;
    }
}
.noteing-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    background-color: #fff;
    max-height: 350px;
    overflow-y: auto;
}
.noteing-body{
    padding: 10px 10px 40px 10px;
}
.noteing-header{
    padding: 5px;
    background: #4eb2b8;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.notes-right-wrapper{
    max-width: 95%;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
}
.note-right {
    background: #f4f4f4;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    margin-left: auto;
    width: fit-content;
    border-bottom-right-radius: 0;
    direction: rtl;
    text-align: justify;
    text-align-last: right;
    line-height: 30px;
    color: #000000;
    font-size: 13px;
    font-weight: 300;
    word-spacing: -2px;
}
.noteing-date{
    text-align: center;
    margin-bottom: 10px;
}
.noteing-date p{
    margin: 0;
    font-size: 11px;
}
.chat-textbox{
    background: #ffffff!important;
    border: none;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    padding-left: 50px;
    font-size: 14px;
    font-weight: 300;
    min-height: 45px;
    border-top: 1px solid #c7c7c7;
}
.btn-send-pm{
    position: absolute;
    top: 5px;
    padding: 0;
    left: 5px;
    width: 35px;
    height: 35px;
    font-size: 15px;
    color: #fff!important;
    background: #4eb2b8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 300ms;
}
.btn-send-pm:hover{
    background: #5aced4;
}
.user-avatar{
    position: relative;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    filter: brightness(1.1);
    margin: 5px 0
}
.user-info-brief h2{
    width: 100%;
    margin: 0;
    line-height: 25px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    text-align: right;
    direction: rtl;
}
.user-info-brief i{
    color: #fff;
}
#call-modal-icoming {
    outline: none!important;
     max-height: unset;
    height: unset;
    width: 350px;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    overflow: hidden;
    background: white;
    padding: 10px;
}
#call-modal-icoming #call-modal-accept {
    background-color: #4eb2b8;
}
#call-modal-icoming #call-modal-reject {
    background-color: #dc3545;
}
#call-modal-icoming .call-modal-button {
    height: 40px;
    width: 100px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    border: none!important;
    outline: none!important;
}
.user-call-req-info {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.user-call-req-info img {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.user-call-req-info h4 {
    width: 100%;
    color: #525252;
    font-weight: 400;
    font-size: 13px;
    margin: 0 0 10px 0;
}
.user-call-req-info p {
    width: 100%;
    color: #a2a2a2;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}
.user-call-connection {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-call-connection i {
    font-size: 23px;
    color: #4eb2b8;
    background: #e9f6f6;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
#call-modal-icoming .call-modal-header {
    color: #4e4e4e;
    font-size: 14px;
    padding: 15px 0;
    font-weight: 400;
}