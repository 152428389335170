@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebthinfanum.woff);
    font-weight: 100;
}

@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanweblightfanum.woff);
    font-weight: 200;
}

@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebregularfanum.woff);
    font-weight: 300;
}

@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebmediumfanum.woff);
    font-weight: 400;
}
@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebboldfanum.woff);
    font-weight: 500;
}

@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebextraboldfanum.woff);
    font-weight: 600;
}
@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebblack.woff);
    font-weight: 700;
}

@font-face {
    font-family: 'iranyekan';
    src: url(iranyekanwebextrablack.woff);
    font-weight: 800;
}
header,body,textarea,input,li,button,p,div,h1,h2,h3,h4,h5,h6,a,span{
    font-family: 'iranyekan';
}