/*-----------------author : kimia kouchaki @copyright served---------------*/
/*--general setting--*/
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
}

html,
body {
    padding: 0;
    margin: 0;
}

body {
    background-color: #f6f6f6 !important;
    font-size: 14px;
    line-height: 22px;
    color: #666;
    position: relative;
    -webkit-text-size-adjust: none;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    direction: rtl;
    text-align: right;
    min-height: 100%;
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
    padding: 0 !important;
    margin: 0 !important;
    min-height: 100%;
    scroll-behavior: smooth;
}

a {
    color: #c1c1c1;
    cursor: pointer !important;
}

.form-control {
    width: 100% !important;
    background: #fff !important;
    color: #474747;
    font-weight: 300;
    font-size: 13px;
    position: relative;
    text-align: right;
    direction: rtl;
    border: 1px solid #c5c5c5;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    transition: border .3s;
}

textarea:focus, input:focus,
textarea:active, input:active,
.btn.focus, .btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.page-link:focus {
    outline: none !important;
    box-shadow: none !important;
}

button:focus,
button:active,
select:focus,
select:active {
    outline: none !important;
    box-shadow: none !important;
}


a:hover {
    text-decoration: none !important;
    color: #1f5b8f;
}

.btn:hover {
    color: #123274;
    text-decoration: none;
}

ul {
    padding-right: 0;
}

.alert {
    font-size: 12px;
    padding: 10px;
}

    .alert i {
        margin-left: 5px;
    }

label {
    font-size: 13px;
    font-weight: 300;
    color: #878787;
}

.form-control::placeholder {
    font-size: 12px;
    font-weight: 300;
}

option {
    font-size: 14px !important;
    font-weight: 300;
}

    option:hover {
        background: #000000 !important;
    }

    option div {
        float: left;
    }

.h-unset {
    height: unset !important;
}

.eng-text {
    font-family: sans-serif !important;
    direction: ltr;
    text-align: left;
}
/*--------------scrollbar--------------*/
@media (min-width: 768px) {
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        -moz-box-shadow: inset 0 0 10px #000000;
        -webkit-box-shadow: inset 0 0 10px #000000;
        box-shadow: inset 0 0 5px #adadad;
        border-radius: 20px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #cdcdcd;
        box-shadow: inset 0 0 5px #9d9d9d;
        border-radius: 20px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #bababa;
        }
}

::selection {
    color: white;
    background: #9e9e9e;
}
/*-----------------sidenav------------------*/
.mm-wrapper__blocker,
.mm-menu_offcanvas {
    z-index: 999;
}

.mm-wrapper_opening [class*=mm-menu_pagedim].mm-menu_opened ~ .mm-wrapper__blocker {
    opacity: .2;
}

.sidebar-container {
    background: #fff;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.05);
    position: relative;
}

.info-side-box {
    background: rgba(78,178,184,1);
    background: url(../images/background.png), linear-gradient(90deg, rgb(54, 143, 151) 0%, rgb(60, 153, 161) 50%, rgba(78,178,184,1) 100%);
    border-radius: 0 0 20px 20px;
    padding: 20px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
}

.avatar-box {
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: brightness(1.1);
}

.user-name {
    width: 100%;
}

    .user-name p {
        width: 100%;
        margin: 10px 0;
        color: #ffffff;
        font-size: 15px;
        font-weight: 500;
    }

    .user-name span {
        display: inline-block;
        font-size: 14px;
        color: #ffffff;
        font-weight: 400;
    }

    .user-name .money {
        font-family: sans-serif;
        font-weight: 600;
    }

.btn-stock {
    max-width: 180px;
    font-size: 13px;
    box-shadow: none !important;
    font-weight: 400;
    border-radius: 20px !important;
    display: flex;
    padding: 8px 5px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    background: #fff !important;
    color: #368f97 !important;
    border: none;
    overflow: hidden !important;
}

    .btn-stock i {
        margin-left: 5px;
    }

.user-dir {
    padding: 10px;
}

    .user-dir p {
        margin-bottom: 10px;
        font-size: 13px;
        color: #888888;
        font-weight: 300;
    }

    .user-dir i {
        font-size: 15px;
        line-height: 20px;
        margin-left: 5px;
    }

        .user-dir i.fa-times-circle {
            color: #E91E63;
        }

        .user-dir i.fa-check-circle {
            color: #009688;
        }

        .user-dir i.fa-exclamation-circle {
            color: #8c8c8c;
        }

    .user-dir span {
        float: left;
        color: #393939;
        display: inline-block;
        height: 100%;
        margin-top: 3px;
        font-weight: 400;
    }

.nav-profile {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: calc( 100vh - 180px );
    padding-top: 20px;
    flex-wrap: nowrap !important;
    overflow-x: hidden;
    overflow-y: auto;
}

    .nav-profile a {
        font-size: 13px;
        color: #707070;
        border-radius: 0 !important;
        border-bottom: 1px solid #f5f5f5;
        font-weight: 500;
        padding: 13px 30px;
        transition-duration: 300ms;
        display: flex;
        align-items: center;
    }

        .nav-profile a span {
            font-size: 11px;
            color: #888888;
            font-weight: 400;
        }

        .nav-profile a:hover,
        .nav-profile a:hover i {
            color: #4eb2b8 !important;
        }

        .nav-profile a i {
            font-size: 15px;
            padding-left: 10px;
            color: #dddddd;
        }

        .nav-profile a.active, .nav-profile .show > .nav-link {
            color: #4eb2b8 !important;
            background-color: transparent !important;
        }

            .nav-profile a.active i, .nav-profile .show > .nav-link i {
                color: #4eb2b8 !important;
            }
/*----------------header---------------*/
.navbar-toggler {
    color: #123274;
    font-size: 20px;
    line-height: 0;
    padding: 10px;
    position: relative;
}

header {
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.05);
    background: #cbe4e6;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    height: 60px;
    position: absolute;
    z-index: 999;
    width: 100%;
}

    header > div {
        width: 100%;
    }

.logo-top {
    margin: 0;
    max-width: 60px;
    padding: 0 10px;
}

.form-control:focus {
    border-color: #dadada !important;
}

.btn-shortcuts {
    color: #123274;
    font-size: 20px;
    line-height: 0;
    padding: 10px;
    position: relative;
}

    .btn-shortcuts .badge {
        position: absolute;
        top: 3px;
        right: -3px;
        font-size: 11px;
        padding: 0px !important;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        border-radius: 50%;
        font-weight: 600;
    }
/*-------------------search form home--------------*/
.btn-search {
    z-index: 9;
}

.blog-search {
    position: absolute;
    z-index: 99;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #f5f5f5;
    height: 0;
    -webkit-transition: height .4s;
    transition: height .4s;
    overflow: hidden;
    align-items: center;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.show-search-area {
    height: 100px;
}

.search-home {
    direction: rtl !important;
    width: 100%;
}

    .search-home h1 {
        font-size: 25px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 15px;
    }

    .search-home p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
        color: #033056;
    }

.search-form-home {
    position: relative;
}

.form-control:focus {
    border-color: #dadada !important;
}

.search-form-home input {
    background: #ffffff !important;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    width: 100% !important;
    color: #474747;
    font-weight: 300;
    font-size: 13px;
    position: relative;
    text-align: right;
    direction: rtl;
    border: 1px solid #f2f4f6 !important;
    height: 40px;
    padding: 0 20px;
    border-radius: 5px;
    transition: border .3s;
    padding-right: 45px;
}

.search-form-home button {
    position: absolute;
    right: 0;
    padding: 0;
    top: 0;
    color: #4aacb3;
    font-size: 17px;
    width: 45px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*-------------------blog single--------------------*/
.blog-header {
    width: 100%;
    background: #ffffff !important;
    position: relative;
    border: none !important;
    padding: 10px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
    display: flex;
    align-items: center;
}

    .blog-header h1 {
        margin: 0;
    }

    .blog-header .mark, .blog-header mark {
        padding: 0 20px;
        background-color: #f1f8f9;
        display: flex;
        width: 100%;
        text-align: center;
        line-height: 40px;
        color: #545454;
        font-weight: 400;
        font-size: 14px;
        overflow: hidden;
        border-radius: 5px;
    }

.blog-header-body {
    width: 100%;
}

.blog-header p {
    margin: 0;
    font-size: 13px;
    color: #6b7c93;
    display: block;
    font-weight: 500;
}

.blog-single-page {
    height: calc( 100vh );
    overflow-y: auto;
    padding: 60px 10px 0 10px;
}

    .blog-single-page .post-wrapper {
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
        background-color: #fff;
        margin: 10px 0;
        padding: 10px 15px;
    }

        .blog-single-page .post-wrapper video {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
            overflow: hidden;
            margin-bottom: 20px;
        }

        .blog-single-page .post-wrapper audio {
            width: 100%;
            margin-bottom: 20px;
            border-radius: 10px;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .09);
            background: #f1f3f4;
        }

.blog-single .date-published p {
    font-size: 12px;
    color: #6b7c93;
    display: block;
    font-weight: 600;
    margin: 15px 0 10px 0;
    font-family: sans-serif;
}

.blog-single .card-title {
    margin-top: 0;
    text-align: right;
    color: #3d959c;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.blog-single img {
    display: flex;
    width: 100% !important;
    max-width: 600px !important;
    margin: auto;
    height: unset !important;
    height: auto !important;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 9%);
}

.post-text p {
    line-height: 30px;
    color: #464646;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
}

.post-text li {
    line-height: 30px;
    color: #464646;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    padding: 5px;
    list-style-type: persian;
    list-style-position: inside;
}

.post-text h3 {
    color: #464646;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.blog-single .post-actions {
    border-top: 1px solid #e8e8e8;
    padding: 10px 0 20px 0;
}

.comments-wrapper {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
}

    .comments-wrapper p.desc {
        font-size: 15px;
        color: #3e3e3e;
        margin: 15px 0 10px;
    }

.comment {
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
}

    .comment .img-wrapper {
        background-color: #fff;
        width: 50px;
        height: 50px;
        background-image: url(../images/user.png);
        border-radius: 50%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    }

    .comment h4 {
        margin-top: 0;
        text-align: right;
        line-height: 25px;
        margin-bottom: 5px;
        color: #686868;
        font-size: 14px;
        font-weight: 500;
    }

    .comment p {
        line-height: 24px;
        color: #5c5c5c;
        font-size: 13px;
        font-weight: 300;
        text-align: right;
        margin: 0 0 10px 0;
    }

    .comment .date-published {
        font-size: 11px;
        color: #898989;
        display: block;
        font-family: sans-serif;
        font-weight: 400;
        float: left;
    }

    .comment:last-child {
        border: none;
    }

.post-author {
    padding: 15px;
    border: 1px solid #47a8af54;
    border-radius: 20px;
    margin-bottom: 20px;
}

.all-this-author {
    background: #f0f0f0;
    color: #464646;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
}

.post-author .img-wrapper {
    background-color: #fff;
    width: 80px;
    height: 80px;
    background-image: url(../images/user.png);
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.post-author h4 {
    font-size: 15px;
    color: #3e3e3e;
    margin: 0px 0 10px;
}

.post-author h2 {
    margin-top: 0;
    text-align: right;
    line-height: 25px;
    margin-bottom: 5px;
    color: #393939;
    font-size: 14px;
    font-weight: 400;
}

.post-author p {
    line-height: 25px;
    color: #464646;
    font-size: 13px;
    font-weight: 300;
    text-align: justify;
    list-style-type: persian;
    list-style-position: inside;
    margin-top: 5px;
    margin-bottom: 0;
}

.post-author .author-position {
    font-size: 11px;
    color: #898989;
    display: block;
    font-weight: 400;
}
/*----------------------blog category-------------------------*/
.blog-cats {
    padding: 10px;
}

.blog-cat-card {
    width: 100%;
    background: #ffffff !important;
    position: relative;
    border: none !important;
    padding: 20px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
    height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 0 0 0;
}

    .blog-cat-card p {
        line-height: 25px;
        color: #575757;
        font-weight: 500;
        font-size: 20px;
        overflow: hidden;
        transition: color .3s;
        margin-bottom: 0;
    }

        .blog-cat-card p span {
            font-size: 13px;
            color: #6b7c93;
            font-weight: 600;
            margin-right: 5px;
            font-family: sans-serif;
        }

    .blog-cat-card i {
        position: absolute;
        bottom: -10px;
        left: 0;
        font-size: 70px;
        color: #4cafb5;
        transform: rotate(25deg);
        -ms-transition: -ms-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
    }

    .blog-cat-card:hover i {
        transform: rotate(0);
    }
/*---------------------blog card----------------------*/
.blog-page {
    height: calc( 100vh );
    overflow-y: auto;
    padding-top: 60px;
}

.blog-posts {
    padding: 10px;
}

.card-blog-home {
    width: 100%;
    background: #ffffff !important;
    position: relative;
    border: none !important;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
    margin: 20px 0 0 0;
}

    .card-blog-home:hover {
        cursor: pointer;
    }

    .card-blog-home .card-title {
        text-align: right;
        line-height: 25px;
        margin: 10px 0 15px 0;
        color: #5a5a5a;
        font-weight: 400;
        font-size: 14px;
        height: 40px;
        overflow: hidden;
        transition: color .3s;
    }

.blog-img-holder {
    height: 150px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    position: relative;
}

.card-blog-home .card-body {
    padding: 10px;
}

.card-blog-home .date-published p {
    font-size: 12px;
    color: #6b7c93;
    display: block;
    font-weight: 600;
    font-family: sans-serif;
    direction: ltr;
    text-align: right;
}

.card-blog-home p {
    width: 100%;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
    color: #3a3a3a;
    font-weight: 300;
    text-align: justify;
    line-height: 25px;
}

.card-blog-home img {
    border-radius: 0;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    -webkit-transform: scale(1.05) translateY(-50%);
    transform: scale(1.05) translateY(-50%);
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
}

.card-blog-home:hover img {
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
}

.badge-content-type {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    font-size: 15px;
    color: #fff;
    background: #4eb2b8;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    justify-content: center;
}
/*---------------postactions---------------------*/
.action-icon i {
    color: #c1c1c1;
    font-size: 17px;
    margin: 0;
    padding: 5px;
    background: #fafafa;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 200ms;
}

.action-icon a {
    font-size: 11px;
    display: flex;
    align-items: center;
    color: #5f5f5f !important;
    font-weight: 600;
    font-family: sans-serif;
}

.like:hover i {
}

.like.active i {
    background: #fdeaf1;
    color: #ef5388;
}

.cm:hover i {
}

.cm.active i {
    background: #e9f5f6;
    color: #4db1b7;
}

.bookmark:hover i {
}

.bookmark.active i {
    background: #ddecee;
    color: #369098;
}
/*------------------------profile edit form-------------------------------*/
/*----------header-------------*/
.page-title {
    line-height: 25px;
    color: #575757;
    font-weight: 500;
    font-size: 12px;
    overflow: hidden;
    transition: color .3s;
    margin-bottom: 0;
}

.btn-back {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .btn-back i {
        font-size: 20px;
        color: #575757;
    }

.btn-save {
    line-height: 25px;
    color: #379098 !important;
    font-weight: 500;
    font-size: 13px;
    overflow: hidden;
    transition: color .3s;
    margin-bottom: 0;
    width: 100%;
    background: #f0f0f0;
    will-change: background;
    transition: background .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    height: 40px;
}

    .btn-save:hover {
        background: #e0e0e0;
    }

.profile-edit-form small {
    margin-right: 5px;
    font-size: 11px;
    font-weight: 400;
    color: #E91E63;
}

.profile-edit {
    padding: 15px 10px;
}

.pro-pic-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.pro-pic-cont {
    width: 100px;
    height: 100px;
    display: flex;
    position: relative;
}

    .pro-pic-cont i {
        position: absolute;
        top: 70%;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 20px;
        color: #8f8f8f;
    }
/*
 * FilePond Custom Styles
 */
.filepond--drop-label.filepond--drop-label label {
    font-size: 13px;
    color: #7f7f7f;
    font-weight: 400;
    cursor: pointer;
}

.filepond--label-action {
    outline: none !important;
    display: inline-block;
    width: 100%;
    font-size: 20px !important;
}

.filepond--panel-root {
    background-color: #edf0f4;
}

.filepond--root {
    width: 130px;
    margin: 0 auto 10px auto;
}

    .filepond--root .filepond--drop-label {
        background: #f0f0f0;
    }

.profile-edit-form .form-group {
    position: relative;
}

.profile-edit-form .form-control {
    padding-right: 10px;
    text-align: right;
    font-size: 12px !important
}

.profile-edit-form label {
    margin: 0;
    font-size: 12px;
}
/*------------------bottom panel------------------*/
.mm-menu_position-bottom {
    min-width: 300px;
    padding: 10px;
    width: 300px;
    max-width: 100vw !important;
    height: 140px;
    min-height: 100px;
    max-height: 100vh;
    left: calc( 50% - 150px );
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.05);
    background: #fff !important;
    border-radius: 10px 10px 0 0;
}

    .mm-menu_position-bottom .mm-panels > .mm-panel {
        overflow: hidden;
    }

    .mm-menu_position-bottom .mm-listitem {
        border: none !important;
        background: transparent;
        padding: 10px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
    }
    /*.mm-menu_position-bottom .mm-wrapper_opening [class*=mm-menu_pagedim].mm-menu_opened~.mm-wrapper__blocker {*/
    /*    opacity: .5;*/
    /*    -webkit-transition: opacity .2s ease .1s;*/
    /*    -o-transition: opacity .2s ease .1s;*/
    /*    transition: opacity .2s ease .1s;*/
    /*}*/
    .mm-menu_position-bottom .mm-panels > .mm-panel {
        background: #fff;
    }

.mm-listitem:after {
    border: none !important;
}
/*-----------------setting page--------------*/
.btn-setting {
    font-size: 13px;
    color: #707070;
    border-radius: 0 !important;
    font-weight: 400;
    margin: 0;
    padding: 0;
    border: 0;
}

    .btn-setting i {
        margin-left: 5px;
    }

.setting-wrapper {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    background-color: #fff;
    margin: 70px 0 10px 0;
    padding: 10px;
    height: calc( 100vh - 80px ) !important;
    overflow-y: auto;
}

.setting-widget label {
    font-size: 13px;
    color: #707070;
    border-radius: 0 !important;
    font-weight: 400;
    margin: 0;
}

.setting-widget .custom-switch label {
    width: 100%;
}

.custom-switch .custom-control-label::after {
    left: 2px;
}

.setting-widget .custom-control-label::before {
    left: 0 !important;
    right: unset !important;
}

.setting-widget .custom-switch {
    padding: 0 !important;
}

.setting-widget {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 0;
    border-left: 0;
    padding: 15px 10px;
}

    .setting-widget a.nav-this {
        position: absolute;
        left: 15px;
        font-size: 21px;
        color: #575757;
        height: 24px;
        width: 40px;
        top: calc( 50% - 12px );
        display: flex;
        justify-content: flex-end;
        z-index: 9;
    }

.setting-widget-title p {
    font-size: 14px;
    color: #707070;
    border-radius: 0 !important;
    font-weight: 500;
    transition-duration: 300ms;
    margin: 0;
}

.btn-delete-all {
    padding: 0 5px;
    margin: 0;
    color: #484848;
}

.btn-occ {
    padding: 0;
    font-size: 13px;
    color: #484848;
}

.profile-sector {
    background: url(../images/background.png),linear-gradient(90deg, rgb(54, 143, 151) 0%, rgb(60, 153, 161) 50%, rgba(78,178,184,1) 100%);
    border-radius: 20px;
    display: inline-block;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

    .profile-sector .setting-widget-title p {
        color: #fff !important;
    }

    .profile-sector a.nav-this {
        color: #fff;
    }
/*--------------------------login-----------------------*/
.login-page {
    background: #f4f4f4;
    min-height: 100vh;
}

.logo-login {
    display: flex;
    max-width: 120px;
}

.login-header {
    min-height: 200px;
    background: rgba(45,130,140,1);
    background: linear-gradient(90deg, rgba(27,94,99,1) 0%, rgba(45,130,140,1) 50%, rgb(45, 130, 140) 100%);
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.05);
}

    .login-header .bg-logo {
        background-image: url(../images/background.png);
        width: 100%;
        min-height: 200px;
        height: 100%;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.login-page .btn-link {
    color: #717171 !important;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: -.1px;
    text-decoration: none;
    font-weight: 500;
    text-align: justify;
    margin-top: 10px;
}

.btn-link span {
    color: #4eb2b8;
    font-size: 13px;
    margin-right: 5px;
}

.login-input {
    width: 100% !important;
    background: #fff !important;
    color: #474747;
    font-weight: 400;
    font-size: 13px;
    position: relative;
    text-align: right;
    direction: rtl;
    border: 1px solid #cbcbcb;
    height: 40px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: border .3s;
}

.forgot-pass {
    border-bottom: 1px dashed #38939b;
    color: #38939b;
    font-size: 10px;
    margin-top: 5px;
    font-weight: 500;
}

.login-container small {
    margin-right: 5px;
    font-size: 11px;
    font-weight: 500;
    color: #E91E63;
}

.btn-sign-in {
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
    background: #4eb2b8;
    box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29);
    padding: 5px 20px !important;
    line-height: 28px;
    border: none;
    color: #fff !important;
    will-change: background;
    transition: background .3s;
    font-size: 13px;
    font-weight: 600;
    align-items: center;
    display: inline-flex;
    justify-content: center;
}

    .btn-sign-in:hover {
        background: #449ca1;
    }

.login-container form {
    padding: 20px 10px;
}

.login-caption .btn {
    color: #878787;
    font-size: 11px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin: 15px 0 20px 0;
}

.login-social {
    padding: 0 8px;
}

    .login-social .btn {
        color: white;
        font-size: 11px;
        padding: 10px;
        width: 100%;
        border: none;
        border-radius: 5px;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
        font-weight: 300;
        will-change: background;
        transition: background .3s;
    }

        .login-social .btn i {
            margin-right: 5px;
        }

.btn-login-fb {
    background: #3b5998;
}

    .btn-login-fb:hover {
        background: #355088;
    }

.btn-login-twitter {
    background: #55acee;
}

    .btn-login-twitter:hover {
        background: #4fa0dd;
    }

.btn-login-google {
    background: #dd4b39;
}

    .btn-login-google:hover {
        background: #c54333;
    }

.cap-focus {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

    .cap-focus label {
        margin: 0;
        position: absolute;
        top: 0;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 12px;
        cursor: text;
        transition-duration: 300ms;
    }

    .cap-focus.focused label {
        top: -30px !important;
        font-size: 10px;
        cursor: unset !important;
    }
/*------------bootstrap switch------------------*/
.login-container .custom-switch {
    padding-right: 2.25rem;
    padding-left: 0;
}

.login-container .custom-control-input {
    right: 0;
    left: unset !important;
}

.login-container .custom-switch .custom-control-label::before {
    left: unset !important;
    right: -2.25rem;
}

.login-container .custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: unset;
    right: calc(-2.25rem + 2px);
}

.login-container .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(-.75rem);
    transform: translateX(-.75rem);
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4eb2b8;
    background-color: #4eb2b8;
}
/*----------------loading effect on click--------------*/
.btn-load-effect.btn--loading {
    font-size: 0;
}

    .btn-load-effect.btn--loading .dot-holder {
        display: flex;
    }

        .btn-load-effect.btn--loading .dot-holder .dot {
            animation-direction: alternate;
            animation-duration: .5s;
            animation-fill-mode: none;
            animation-iteration-count: infinite;
            animation-name: stretch;
            animation-play-state: running;
            animation-timing-function: ease-out;
            border-radius: 100%;
            display: block;
            height: 10px;
            margin: 0 1px;
            width: 10px;
            animation-delay: .1s;
            margin: 0 5px;
        }

            .btn-load-effect.btn--loading .dot-holder .dot:first-child {
                animation-delay: 0s;
                margin: 0;
            }

            .btn-load-effect.btn--loading .dot-holder .dot:last-child {
                animation-delay: .2s;
                margin: 0;
            }

.btn-load-effect .dot {
    font-weight: normal;
}

.btn-load-effect .dot-holder {
    display: none;
}

@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #B5DDF0;
    }

    50% {
        background-color: #CFE9F6;
    }

    100% {
        transform: scale(1);
        background-color: #FFFFFF;
    }
}
/*----------------start page--------------*/
.dot-holder-start {
    display: flex;
}

    .dot-holder-start .dot-start {
        animation-direction: alternate;
        animation-duration: .5s;
        animation-fill-mode: none;
        animation-iteration-count: infinite;
        animation-name: stretch;
        animation-play-state: running;
        animation-timing-function: ease-out;
        border-radius: 100%;
        display: block;
        height: 10px;
        margin: 0 1px;
        width: 10px;
        animation-delay: .1s;
        margin: 0 5px;
    }

        .dot-holder-start .dot-start:first-child {
            animation-delay: 0s;
            margin: 0;
        }

        .dot-holder-start .dot-start:last-child {
            animation-delay: .2s;
            margin: 0;
        }

    .dot-holder-start .dot-start {
        font-weight: normal;
    }
/*-------------------verify code page---------------*/
.edit-phone {
    border-bottom: 1px dashed #38939b;
    color: #38939b;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 5px;
}

.activation-code-input {
    display: none;
}

.activation-code {
    direction: ltr;
    position: relative;
}

    .activation-code .activation-code-inputs {
        display: flex;
        /*flex-direction: row;*/
        /*flex-wrap: nowrap;*/
        flex-flow: row nowrap;
    }

        .activation-code .activation-code-inputs input {
            display: flex;
            flex-flow: column nowrap;
            padding: 0;
            border: 0;
            outline: 0;
            min-width: 0;
            line-height: 36px;
            text-align: center;
            align-items: center;
            transition: all 0.3s ease;
            border-bottom: 2px solid;
            border-color: #ccc;
            margin-right: 8px;
            /*background: red;*/
        }

            .activation-code .activation-code-inputs input:last-child {
                margin-right: 0;
            }

            .activation-code .activation-code-inputs input:focus {
                border-color: #46b2f0 !important;
            }
/*--------------------footer--------------------*/
footer {
    background: #fff;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    height: 60px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    z-index: 9;
}

.footer-menu i {
    color: #b3b3b3;
    font-size: 16px;
    line-height: 0;
    padding: 5px;
    position: relative;
}

.footer-menu p {
    line-height: 15px;
    color: #575757;
    font-weight: 500;
    font-size: 10px;
    overflow: hidden;
    transition: color .3s;
    margin-bottom: 0;
}

.footer-menu .active i,
.footer-menu .active p {
    color: #4cafb5 !important;
}
/*-------------------------------faq page----------------*/
.accordion .card-header {
    border: 0;
}

.accordion .card-body {
    font-size: 13px;
    line-height: 25px;
    font-weight: 300;
    color: #3c3c3c;
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin: 5px 0 10px 0;
    background: white;
    padding: 10px;
    text-align: justify;
}

.btn-faq {
    padding: 10px;
    margin: 0 0 5px 0;
    font-size: 14px;
    text-align: right;
    direction: rtl;
    font-weight: 400;
    background: #dfdfdf !important;
    line-height: 30px;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #535353 !important;
    border-radius: 10px !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

    .btn-faq i {
        font-size: 15px;
    }

.accordion .card {
    background: transparent;
    padding: 0;
    border: 0;
}

.accordion .card-header {
    background: transparent;
    padding: 0;
    margin-bottom: 0 !important;
}
/*------------------about-----------------*/
.about-wrapper {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    margin: 10px 0;
    padding: 15px;
    background: rgba(78,178,184,1);
    background: url(../images/bg-about.png), linear-gradient(90deg, rgb(54 143 151 / 17%) 0%, rgb(60 153 161 / 6%) 50%, rgb(78 178 184 / 19%) 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

    .about-wrapper h4 {
        text-align: center;
        font-size: 15px;
        color: #39939b;
        font-weight: 500;
        margin: 20px 0 10px 0;
        line-height: 30px;
    }

    .about-wrapper p {
        line-height: 30px;
        color: #464646;
        font-size: 14px;
        font-weight: 300;
        text-align: justify;
        text-align-last: center;
    }

.about-logo img {
    width: 100%;
    max-width: 50px;
    margin: 0 auto 20px auto;
    display: flex;
}
/*-------------------category for consult------------*/
.consult-category-page {
    max-height: calc( 100vh );
    overflow-y: auto;
    padding-top: 60px;
}

.consult-cat-widget {
    width: 100%;
    height: 120px;
    position: relative;
    border-radius: 10px 10px 10px 40px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    overflow: hidden;
    margin: 10px 0 0 0;
    padding: 10px 120px 10px 10px;
    background: white;
    display: flex;
    align-items: center;
    border-bottom: 4px solid #77bfc4;
}

.consult-cat-widget__body {
    z-index: 9;
}

.consult-cat-widget:hover h2 {
    color: #368f97 !important;
}

.consult-cat-widget:hover .cat-image {
    transition-duration: 300ms;
    width: 140px;
    height: 140px;
}

.consult-cat-widget h2 {
    width: 100%;
    text-align: center;
    margin-top: 0;
    line-height: 25px;
    margin-bottom: 15px;
    color: #525252;
    font-weight: 500;
    font-size: 14px;
}

.consult-cat-widget .cat-image {
    width: 150px;
    height: 120px;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
}

.dr-icons {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    font-size: 15px;
    color: lightgrey;
}

    .dr-icons span {
        margin-right: 3px;
    }

.dr-icon {
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-right: -5px;
    border: 1px solid white;
}

    .dr-icon:first-child {
        margin-right: 0 !important;
    }
/*-----------------doctors list--------------*/
.doctors-page {
    max-height: calc( 100vh );
    padding-top: 60px;
    overflow-y: auto;
}

.doctor-widget {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 11%), 0 3px 6px rgb(0 0 0 / 5%);
    overflow: hidden;
    margin: 10px 0 0 0;
    background: white;
    border-bottom: 3px solid #4cafb5;
    padding: 10px;
}

.doctor-widge-wrapp {
    position: relative;
    z-index: 9;
}

.doctor-widget h2 {
    width: 100%;
    text-align: center;
    margin: 5px 0;
    line-height: 25px;
    color: #525252;
    font-weight: 400;
    font-size: 14px;
    height: 25px;
    overflow: hidden;
}

.parvane-no {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

    .parvane-no p {
        margin: 0;
        font-size: 12px;
        color: #999999;
        display: block;
        font-weight: 400;
    }

    .parvane-no span {
        margin-right: 5px;
    }

.doctor-image {
    position: relative;
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    margin-top: 20px;
    filter: brightness(1.1);
}

.online-badge {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #4baeb4;
    position: absolute;
    bottom: 5px;
    right: 0;
    border: 2px solid #E5F3F4;
}

.rate-teaser {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #999999;
    font-weight: 500;
    height: 32px;
}

    .rate-teaser i {
        font-size: 15px;
        height: 20px;
        margin-left: 3px;
        color: #ffd146;
    }

.cm-teaser a {
    color: #999999;
}

.cm-teaser {
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #999999;
    font-weight: 500;
    border-radius: 5px;
    z-index: 99;
}

    .cm-teaser i {
        font-size: 15px;
        height: 20px;
        margin-left: 3px;
        /*color: #ffd146;*/
    }

.contact-ways-doctor {
    margin: 0;
    font-size: 12px;
    color: #8b8b8b;
    font-weight: 400;
    padding-top: 10px;
}

    .contact-ways-doctor i {
        margin: 0 5px;
        font-size: 15px;
        color: #4eb2b8;
    }

.btn-way-contact {
    font-size: 11px;
    border-radius: 20px;
    color: #8b8b8b !important;
    font-weight: 400;
    margin: 0 5px;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    cursor: default !important;
}

.doctor-widget__body p {
    width: 100%;
    font-size: 12px;
    margin-bottom: 0;
    color: #5f5f5f;
    font-weight: 400;
    text-align: center;
    line-height: 25px;
    height: 50px;
    overflow: hidden;
}

.btn-start-consult {
    width: 100%;
    background: #4eb2b8;
    box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29);
    padding: 5px 20px !important;
    line-height: 28px;
    border: none;
    display: inline-block;
    color: #fff !important;
    will-change: background;
    transition: background .3s;
    font-size: 13px;
    font-weight: 400;
    border-radius: 0;
}

    .btn-start-consult:hover {
        background: #479ca1;
    }

.btn-reserve-offline {
    width: 100%;
    background: #ffd555;
    box-shadow: 0 3px 6px rgb(255 193 7 / 29%), 0 1px 3px rgb(255 193 7 / 22%);
    padding: 5px 20px !important;
    line-height: 28px;
    border: none;
    display: inline-block;
    color: #fff !important;
    will-change: background;
    transition: background .3s;
    font-size: 13px;
    font-weight: 400;
    border-radius: 0;
}

    .btn-reserve-offline:hover {
        background: #ffce46;
    }
/*-----------------doctor single page--------------*/
.doctor-single-page-wrapper {
    max-height: calc( 100vh );
    padding-top: 60px;
    overflow-y: auto;
}

    .doctor-single-page-wrapper ul {
        list-style: none;
    }

    .doctor-single-page-wrapper li {
        width: 100%;
        color: #646464;
        font-weight: 300;
        text-align: justify;
        line-height: 25px;
        font-size: 13px;
        margin: 5px 0;
        padding: 2px 10px;
        border-radius: 5px;
    }

        .doctor-single-page-wrapper li i {
            margin-left: 5px;
            color: #4aadb3;
        }

.doctor-single-page {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    background-color: #fff;
    overflow: hidden;
    padding: 0;
}

.doctor-bg {
    padding: 5px;
    background: url(../images/bg-about.png), linear-gradient(90deg, rgb(223 238 239) 0%, rgb(236 245 245) 50%, rgb(222 237 238) 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom: 4px solid #4cafb5;
    border-radius: 0 0 20px 30px;
}

.doctor-single-page-plans {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    background-color: #fff;
    padding: 15px;
    max-height: 1010px;
    overflow-y: auto;
}

    .doctor-single-page-plans.h-100vh {
        max-height: calc(100vh - 60px);
        overflow-y: auto;
    }

.intro-video {
    width: 100%;
    border-radius: 10px;
    border: none !important;
    outline: none !important;
}

.doctor-single-page h3,
.doctor-single-page-plans h3 {
    font-weight: 300;
    width: fit-content;
    font-size: 14px;
    color: #5c5c5c;
    margin: 10px auto;
    text-align: center;
    padding-bottom: 5px;
}

.doctor-single-page .doctor-image {
    margin: 0;
    width: 90px;
    height: 90px;
}

.doctor-single-page .online-badge {
    right: 5px;
}

.doctor-single-page .rate-teaser {
    position: relative;
    right: unset;
    bottom: unset;
    top: unset;
    left: unset;
    font-size: 11px;
    color: #606060;
    padding: 10px 0;
    font-weight: 400;
}

.doctor-single-page h2 {
    width: 100%;
    margin: 0 0 5px 0;
    line-height: 25px;
    color: #338b93;
    font-weight: 500;
    font-size: 16px;
}

.doctor-single-page__body {
    width: 100%;
    padding: 15px;
}

    .doctor-single-page__body p {
        width: 100%;
        color: #646464;
        font-weight: 300;
        text-align: justify;
        line-height: 30px;
        margin: 0 0 15px 0;
        font-size: 14px;
    }

.p-no {
    font-weight: 300 !important;
    color: #676767 !important;
    font-size: 12px !important;
}

.consult-plans {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 8px 6px -6px #8686867d;
    overflow: hidden;
    margin: 10px 0;
    padding: 35px 10px 10px 10px;
    background: #f7f7f7;
    cursor: pointer !important;
}

    .consult-plans.active {
        background: #1a949b;
    }

        .consult-plans.active .plan-price,
        .consult-plans.active .plan-type span {
            color: #fff;
        }

        .consult-plans.active p {
            color: whitesmoke;
        }

        .consult-plans.active .plan-type i {
            color: #ffd146;
        }

    .consult-plans p {
        width: 100%;
        color: #7b7b7b;
        font-weight: 400;
        text-align: right;
        line-height: 20px;
        font-size: 11px;
        padding: 5px;
        margin: 0;
    }

.plan-type {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
}

    .plan-type i {
        margin-left: 5px;
        font-size: 15px;
        color: #4eb2b8;
    }

    .plan-type span {
        margin: 0;
        color: #525252;
        font-weight: 400;
        font-size: 12px;
    }

.plan-price {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    margin: 0;
    color: #353535;
    font-weight: 500;
    font-size: 12px;
}

    .plan-price span {
        font-family: sans-serif;
        padding-right: 5px;
        direction: ltr;
    }
/*---------------notifications--------------*/
.nav-notification {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

    .nav-notification .nav-link {
        font-size: 13px;
        font-weight: 400;
        color: #1d1d1d;
        padding: 7px 13px;
    }

        .nav-notification .nav-link.active, .nav-pills .show > .nav-link {
            color: #fff;
            background-color: #4eb2b8;
            border-radius: 50px;
            box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29);
        }

.notif-widget {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    overflow: hidden;
    margin: 10px 0 0 0;
    background: white;
    padding: 10px;
    padding-left: 0px;
    height: calc( 100% - 10px);
    border-bottom: 3px solid #4cafb5;
}

    .notif-widget h2 {
        width: 100%;
        line-height: 30px;
        color: #525252;
        font-weight: 400;
        font-size: 13px;
        margin: 0;
        height: 25px;
        overflow: hidden;
    }

    .notif-widget .doctor-image {
        margin-top: 10px;
    }

.notif-widget__body p {
    width: 100%;
    color: #7b7b7b;
    font-weight: 400;
    text-align: right;
    line-height: 20px;
    font-size: 10px;
    margin: 20px 0 10px 0;
    display: flex;
    align-items: center;
}

.notif-name p {
    margin: 0;
    font-size: 11px;
    color: #979797;
    font-weight: 300;
}

.notif-widget__body i {
    margin-left: 8px;
    font-size: 17px;
    color: #4eb2b8;
}

.user-image {
    position: relative;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    border: 1px solid #fff;
}

.btn-acc {
    width: 100%;
    background: #4eb2b8;
    box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-size: 11px;
    font-weight: 400;
    border-radius: 5px;
    height: 30px;
    margin: 10px 0;
}

.btn-dec {
    width: 100%;
    background: #b3b3b3;
    box-shadow: 0 3px 6px rgba(179, 179, 179, 0.18), 0 1px 3px rgba(179, 179, 179, 0.23);
    padding: 5px 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    height: 30px;
    margin: 10px 0;
}

    .btn-dec:hover, .btn-acc:hover {
        background: #bbbbbb;
    }
/*-------------archive page--------------*/
.archive-widget {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    overflow: hidden;
    margin: 10px 0 0 0;
    background: white;
    padding: 10px;
    height: calc( 100% - 10px);
    border-bottom: 3px solid #4cafb5;
}

.user-side {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

    .user-side img {
        position: relative;
        width: 50px;
        height: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        margin-bottom: 5px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    }

    .user-side h4 {
        width: 100%;
        color: #525252;
        font-weight: 400;
        font-size: 13px;
        margin: 0
    }

    .user-side p {
        width: 100%;
        color: #a2a2a2;
        font-weight: 400;
        font-size: 12px;
        margin: 0;
    }

.user-connection {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .user-connection i {
        font-size: 20px;
        color: #4eb2b8;
        background: #e9f6f6;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

.archive-detail {
    padding: 0;
    margin-top: 15px;
}

.archive-detail-widget {
    padding: 5px;
    background: #e6e6e6;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

    .archive-detail-widget p {
        width: 100%;
        color: #848484;
        font-weight: 500;
        font-size: 10px;
        margin: 0;
        text-align: center;
    }

    .archive-detail-widget span {
        font-weight: 500;
        display: inline-block;
        width: 100%;
        font-size: 12px;
        color: #818181;
    }

.your-feedback {
    margin-top: 10px;
}

    .your-feedback h4 {
        font-weight: 300;
        display: inline-block;
        font-size: 13px;
        color: #818181;
        margin: 0;
    }

.star-wrapper label {
}
/*-----------payment page--------------*/
.payment-paypal {
    font-family: sans-serif !important;
    text-align: center;
    direction: ltr;
}

    .payment-paypal.pay-result p {
        font-family: sans-serif !important;
        text-align: center;
    }

    .payment-paypal.pay-result .small-txt {
        font-size: 13px !important;
    }

.wallet-page {
    height: calc( 100vh - 60px );
    overflow-y: auto;
    padding-top: 0px;
}

.current-money {
    width: 100%;
    text-align: center;
    padding: 0;
}

    .current-money p {
        font-weight: 400;
        width: 100%;
        font-size: 12px;
        color: #8b8b8b;
        margin: 10px 0;
        text-align: center;
    }

    .current-money h4 {
        font-size: 25px;
        color: #4eb2b8;
        font-family: sans-serif;
    }

.wallet-page .form-group {
    position: relative;
}

.pay-card {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    overflow: hidden;
    margin: 10px 0 0 0;
    background: white;
    padding: 10px;
    max-height: calc( 100vh - 90px );
    overflow-y: auto;
}

.wallet-form {
    width: 100%;
    background: #fff !important;
    color: #474747;
    font-weight: 400;
    font-size: 15px;
    position: relative;
    font-family: sans-serif;
    direction: rtl;
    border: 1px solid #d3d3d3;
    height: 40px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: border .3s;
    text-align: center;
}

.pay-card p {
    font-weight: 400;
    width: 100%;
    font-size: 12px;
    color: #8b8b8b;
    margin: 10px 0;
    text-align: center;
}

.quickLinksWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.quickPayLinks {
    display: inline-block;
}

.customPayButton {
    display: flex;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    font-size: 15px;
    border: 1px solid #cecece;
    color: #707070;
    align-items: center;
    justify-content: center;
    padding: 7px 15px 5px 15px;
    line-height: 20px;
    font-weight: 300;
    transition-duration: 300ms;
    font-family: sans-serif;
}

    .customPayButton:hover, .customPayButton.active {
        border-color: #4eb2b8;
        color: #4eb2b8;
    }

.money-indec-btn {
    position: absolute;
    top: 5px;
    z-index: 9;
    width: 35px;
    height: 35px;
    background: #eeeeee;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #666666 !important;
}

    .money-indec-btn:hover {
        box-shadow: none !important;
    }

    .money-indec-btn.left {
        left: 5px;
        font-size: 30px;
    }

    .money-indec-btn.right {
        right: 5px;
        font-size: 25px;
    }

.transaction-wrapper {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    overflow: hidden;
    margin: 10px 0 0 0;
    background: white;
    padding: 10px;
    max-height: calc( 100vh - 90px );
    overflow-y: auto;
}

.transaction-list {
    margin: 0;
}

.transaction-head {
    font-weight: 400;
    width: 100%;
    font-size: 12px;
    color: #8b8b8b;
    margin: 10px 0;
    text-align: center;
}

.transaction-list {
    list-style: none;
}

    .transaction-list li {
        padding: 10px 10px 5px 10px;
        border-bottom: 1px solid #e2e2e2;
    }

        .transaction-list li:last-child {
            border: 0;
        }

    .transaction-list p {
        margin: 0;
        font-weight: 400;
        width: 100%;
        font-size: 12px;
        color: #646464;
    }

    .transaction-list span {
        float: left;
        font-size: 14px;
        direction: ltr;
    }

        .transaction-list span i {
            color: #FFC107;
        }

        .transaction-list span.minus {
            color: #F44336;
            font-family: sans-serif;
        }

        .transaction-list span.added {
            color: #009688;
            font-family: sans-serif;
        }

.transaction-date {
    margin: 0;
    font-weight: 300;
    width: 100%;
    font-size: 10px;
    color: #646464;
    margin-top: 5px;
    direction: ltr;
    text-align: right;
}

.miladi-date {
    font-family: sans-serif !important;
    direction: ltr;
    text-align: left;
}
/*------------rated------------*/
.rated {
    direction: rtl;
    unicode-bidi: bidi-override;
    color: #ddd; /* Personal choice */
    width: 100%;
}

    .rated input {
        display: none;
    }
    /*.rated label:hover,*/
    /*.rated label:hover ~ label,*/
    .rated label {
        color: #c5c5c5;
    }

    .rated input.checked + label,
    .rated input.checked + label ~ label {
        color: #ffd146 !important; /* Personal color choice. Lifted from Bootstrap 4 */
    }

/*-----------reserve page----------------*/
.meetings-reserve-archive-card {
    position: relative;
}

    .meetings-reserve-archive-card .reserve-type-icon-big i {
        position: absolute;
        font-size: 100px;
        color: #4eb2b812;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        top: 28%;
    }

.doctor-widget.in-reserve {
    background: url(../images/background.png), linear-gradient(90deg, rgb(54, 143, 151) 0%, rgb(60, 153, 161) 50%, rgba(78,178,184,1) 100%);
    border-radius: 0;
    display: inline-block;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

    .doctor-widget.in-reserve .cm-teaser {
        color: #fff;
    }

    .doctor-widget.in-reserve .doctor-widget__body p {
        height: 30px !important;
    }

    .doctor-widget.in-reserve .parvane-no p {
        color: #dfdfdf;
    }

    .doctor-widget.in-reserve .rate-teaser,
    .doctor-widget.in-reserve h2,
    .doctor-widget.in-reserve .doctor-widget__body p {
        color: #fff;
    }

form.in-reserve {
    margin-top: -40px;
    z-index: 9;
    position: relative;
    background: #fff;
    border-radius: 30px 20px 0 0;
}
/*-----------ticketing--------------*/
.ticketing-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .09);
    margin: 10px 0;
    max-height: calc( 100vh - 70px );
    margin-top: 70px;
    overflow-y: auto;
    background: #dedede;
    background: url(../images/bg-about.png), linear-gradient(90deg, rgb(54 143 151 / 12%) 0%, rgb(60 153 161 / 12%) 50%, rgb(78 178 184 / 19%) 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.ticketing-body {
    padding: 10px 10px 40px 10px;
}

.ticketing-header {
}

.ticket-avatar {
    background-color: #fff;
    width: 40px;
    height: 40px;
    background-image: url(../images/user.png);
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.tickets-right-wrapper {
    padding-right: 50px;
    max-width: 80%;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
}

    .tickets-right-wrapper .ticket-avatar {
        position: absolute;
        bottom: 0;
        right: 0;
    }

.ticket-right {
    background: #ffffff;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #2d2d2d;
    display: flex;
    margin-left: auto;
    width: fit-content;
    border-bottom-right-radius: 0;
}

.tickets-left-wrapper {
    padding-left: 50px;
    max-width: 80%;
    margin-right: auto;
    margin-bottom: 20px;
    position: relative;
}

    .tickets-left-wrapper .ticket-avatar {
        position: absolute;
        bottom: 0;
        left: 0;
    }

.ticket-left {
    background: #38939b;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    display: flex;
    margin-right: auto;
    width: fit-content;
    border-bottom-left-radius: 0;
}

.download-btn-pm {
    padding: 0;
    margin-left: 20px;
    color: #686868 !important;
    font-size: 17px;
}

.file-name-pm {
    display: flex;
    align-items: center;
    direction: ltr;
    font-family: sans-serif;
}

.file-icon-pm i {
    padding: 5px;
    margin-right: 5px;
    color: #686868;
    font-size: 17px;
}

.ticket-left
.file-icon-pm i,
.ticket-left
.download-btn-pm {
    color: #fff !important;
}

.reserve-offer-body {
    width: 100%;
}

    .reserve-offer-body ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .reserve-offer-body ul li {
            font-size: 13px;
            color: #626262;
            font-weight: 200;
            padding: 2px 0;
            height: 28px;
            overflow: hidden;
        }

            .reserve-offer-body ul li span {
                font-weight: 300;
            }

            .reserve-offer-body ul li .miladi-date {
                font-weight: 500;
            }

.ticket-left ul li {
    color: #fff !important;
}

.ticketing-date {
    text-align: center;
    margin-bottom: 10px;
}

    .ticketing-date p {
        margin: 0;
        font-size: 13px;
    }

.btn-new-offer {
    width: 100%;
    height: 30px;
    background: #ffd146;
    box-shadow: 0 3px 6px rgb(255 193 7 / 29%), 0 1px 3px rgb(255 193 7 / 22%);
    padding: 0 !important;
    line-height: 28px;
    border: none;
    color: #fff !important;
    font-size: 11px;
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .btn-new-offer:hover {
        background: #ffd91a;
    }

.ticket-left-time-options {
    display: flex;
    margin-right: auto;
    width: fit-content;
}

    .ticket-left-time-options input {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        margin: 0 !important;
        cursor: pointer !important;
    }

.time-options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #38939b;
    margin-top: 10px;
    font-size: 15px;
    color: #ffffff;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    transition: background 300ms;
    cursor: pointer !important;
    border-radius: 50px 10px 10px 50px !important;
}

.ticket-left-time-options input:checked + label button {
    background: #ffd146;
}
/*------------------payment result------------------*/
.pay-result {
    text-align: center;
}

    .pay-result i.fa-check-circle {
        font-size: 100px;
        color: #53c4cb;
        margin: 20px 0;
    }

    .pay-result i.fa-share {
        font-size: 90px;
        color: #53c4cb;
        margin: 20px 0;
    }

    .pay-result i.fa-times-circle {
        font-size: 100px;
        color: #dc3545;
        margin: 20px 0;
    }

    .pay-result p {
        color: #292929;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -.1px;
        text-decoration: none;
        font-weight: 200;
        text-align: justify;
    }

.btn-next-step {
    background: #4eb2b8;
    color: #fff !important;
    font-size: 15px;
    font-weight: 200;
    border-radius: 5px;
    height: 40px;
    display: inline-block;
    line-height: 25px;
    padding: 6px 20px;
    text-align: center;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    margin: 10px 0;
}

    .btn-next-step:hover {
        box-shadow: none !important;
    }
/*-----------------------archive history------------------*/
.arch-heading-side {
    margin-top: 0;
    text-align: center;
    line-height: 25px;
    margin-bottom: 0;
    color: #464646;
    font-weight: 300;
    font-size: 15px;
    height: 50px;
    overflow: hidden;
    transition: color .3s;
}

.arch-detail {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    overflow: hidden;
    background: white;
    padding: 10px;
    height: calc( 100% - 10px);
    border-bottom: 3px solid #4cafb5;
}

.arch-details p {
    line-height: 25px;
    text-align: justify;
    font-size: 13px;
    color: #888888;
    font-weight: 300;
    text-align-last: center;
    max-width: 700px;
    display: flex;
    margin: 15px auto;
}

.arch-details ul {
    list-style: none;
    margin: 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

    .arch-details ul li {
        line-height: 25px;
        margin: 5px 0 0 0;
        font-size: 12px;
        color: #888888;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #dee2e6 !important;
        text-align: center;
    }

        .arch-details ul li:last-child {
            border-left: none !important;
        }

        .arch-details ul li span {
            width: 100%;
            display: inline-block;
            color: #3c6ef3;
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            margin-left: 5px;
        }

.arch-single-detail {
    margin-bottom: 20px;
}

.arch-detail-item {
    text-align: center;
    border-left: 1px solid #eaeaeaa3;
}

    .arch-detail-item h6 {
        width: 100%;
        display: inline-block;
        color: #2fa9b1;
        font-size: 20px;
        font-weight: 400;
        margin: 10px 0;
    }

    .arch-detail-item i {
        font-size: 20px;
        color: #c9c9c9;
        margin-bottom: 10px;
    }

    .arch-detail-item p {
        margin-bottom: 0;
        font-size: 10px;
        color: #979797;
        font-weight: 500;
    }
/*-----------contact page-----------*/
.contact-form-holder {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
}

    .contact-form-holder p {
        font-size: 13px;
        line-height: 25px;
        font-weight: 300;
        color: #3c3c3c;
        width: 100%;
        text-align: justify;
    }

.address-holder {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ececec;
    border-radius: 0px;
}

    .address-holder:last-child {
        border-bottom: none !important;
    }

    .address-holder p {
        margin-bottom: 0;
        text-align: justify;
        color: #373737;
        font-size: 13px;
        line-height: 25px;
        font-weight: 300;
    }

    .address-holder i {
        float: right;
        margin-left: 10px;
        font-size: 20px;
        color: #4cafb6;
    }

    .address-holder span {
        float: left;
        direction: ltr;
    }

.map-holder {
    border-radius: 10px !important;
    overflow: hidden !important;
}

.social-icons i {
    background: linear-gradient(90deg, rgb(54, 143, 151) 0%, rgb(60, 153, 161) 50%, rgba(78,178,184,1) 100%);
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    font-size: 15px;
    margin: 5px;
}
/*---------------------add content--------------*/
.custom-file-label::after {
    left: 5px;
    right: unset !important;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100% !important;
    background: #fff !important;
    color: #474747;
    font-weight: 300;
    font-size: 14px;
    text-align: right;
    direction: rtl;
    border: 1px solid #c5c5c5 !important;
    height: 50px;
    padding: 15px;
    border-radius: 5px;
}

.custom-file-input,
.custom-file {
    height: 50px;
}

.custom-file-label::after {
    top: 5px !important;
    height: 38px;
    border-radius: 5px;
    background: #4eb2b8;
    box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29);
    padding: 5px 20px !important;
    line-height: 28px;
    margin-right: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: background;
    transition: background .3s;
    font-size: 14px;
    font-weight: 500;
    min-width: 130px;
    color: #fff;
    cursor: pointer !important;
}

    .custom-file-label::after:hover {
        box-shadow: none !important;
        background: #59cbd2;
    }

.custom-file-input:focus ~ .custom-file-label {
    box-shadow: none !important;
}
/*--------------feedback page-----------------*/
/*-----rating-------*/
.rating {
    position: relative;
    direction: rtl;
    unicode-bidi: bidi-override;
    color: #ddd; /* Personal choice */
    width: 100%;
}

    .rating input {
        display: none;
    }

    .rating label:hover,
    .rating label:hover ~ label {
        color: #ffd146;
    }

    .rating label {
        color: #c5c5c5;
    }

    .rating input:checked + label,
    .rating input:checked + label ~ label {
        color: #ffd146 !important; /* Personal color choice. Lifted from Bootstrap 4 */
    }

.star-rating-wrapper label {
    font-size: 25px;
    cursor: pointer !important;
}

    .star-rating-wrapper label span {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        padding: 10px 0;
        justify-content: center;
        align-items: center;
        color: #525252;
        font-weight: 400;
        font-size: 14px;
    }

.pros-cons-tab .btn {
    font-size: 13px;
    font-weight: 400;
    color: #1d1d1d;
    padding: 7px 15px;
    border-radius: 5px;
    width: 90px;
}

.nav-pills .nav-link.btn-pros.active, .nav-pills .show > .nav-link.btn-pros {
    color: #fff;
    background-color: #4eb2b8;
    box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29) !important;
}

.nav-pills .nav-link.btn-cons.active, .nav-pills .show > .nav-link.btn-cons {
    color: #fff;
    background-color: #f84455;
    box-shadow: 0 3px 6px rgb(220 53 69 / 16%), 0 1px 3px rgb(220 53 69 / 12%) !important;
}

.rate-reasons .custom-checkbox {
    border: 1px solid #e2e2e2;
    padding: 10px 30px;
    margin: 5px 0;
    border-radius: 5px;
    background: #ffffff;
    cursor: pointer !important;
}

.rate-reasons label {
    color: #3a3a3a;
    padding-right: 5px;
    cursor: pointer !important;
}

.rate-reasons .custom-checkbox .custom-control-label::before {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -4px;
    box-shadow: none !important;
}

.rate-reasons .custom-control-label::after {
    width: 20px;
    height: 20px;
    margin-top: -4px;
}

.rate-reasons .custom-control-input:checked ~ .custom-control-label {
    color: #4eb2b8;
}

.cons-reasons .custom-control-input:checked ~ .custom-control-label {
    color: #f84455 !important;
}

    .cons-reasons .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #f84455 !important;
        background-color: #f84455 !important;
    }

.rate-reasons .custom-control-label::before {
    right: -1.3rem;
    left: unset;
}

.rate-reasons .custom-control-label::after {
    right: -1.3rem;
    left: unset;
}

.rate-reasons .custom-control {
    padding-right: 1.5rem;
    padding-left: unset;
}
/*-------------prize wheel--------------*/
.wheel-page {
    height: calc( 100vh );
    padding-top: 60px;
    overflow-y: auto;
}

text {
    font-family: iranyekan;
    font-size: 15px;
    font-weight: 400;
    pointer-events: none;
    fill: #fff;
}

#chart {
    position: relative;
    height: 320px;
    width: 350px;
}

    #chart svg {
        position: absolute;
        left: 20px;
        top: -20px;
    }

@media only screen and (max-width: 350px) {
    #chart svg {
        left: 0;
    }
}

#question {
    position: relative;
}

    #question h1 {
        font-size: 20px;
        font-weight: 300;
        padding: 0;
        margin: 15px 0 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
/*-----------------------quiz-------------------*/
.test-widget {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.09);
    background-color: #fff;
    margin: 10px 0;
    padding: 20px 10px;
    overflow-y: auto;
    border-right: 3px solid #4cafb5;
}

.btn-green {
    color: #fff !important;
    background-color: #4eb2b8;
    border: none !important;
    padding: 10px 5px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
}

.num-q .nums {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 50%;
    margin: 5px;
    font-size: 13px;
    color: #757575;
    font-weight: 500;
}

.map-q {
    margin: 15px 10px;
}

    .map-q .map-qr {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-left: 5px;
    }

    .map-q p {
        margin-bottom: 0;
        font-size: 11px;
        color: #6e6e6e;
        font-weight: 400;
    }

    .num-q .red, .map-q .red {
        background: #E91E63;
        color: #fff;
    }

    .num-q .green, .map-q .green {
        background: #4eb2b8;
        color: #fff;
    }

    .map-q .no {
        border: 1px solid #dadada;
    }

.test-img img {
    max-width: 100%;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    display: flex;
}

.big-text {
    font-size: 18px !important;
    margin-bottom: 10px !important;
}

.test-txt h3 {
    color: #FFC107;
    font-size: 25px;
    font-weight: 500;
    margin: 0;
}

.test-txt p {
    margin-bottom: 0;
    font-size: 12px;
    color: #888888;
    font-weight: 300;
}

.num-q .now {
    font-size: 25px;
    font-weight: 500;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 200ms;
    background: #ffc1072e !important;
    color: #FFC107 !important;
    border-radius: 50%;
}

.dashed {
    border-bottom: 1px dashed #a7a7a7;
    width: 40px;
    margin: 0 2px;
}

.question-box {
    margin: 20px auto;
}

    .question-box p {
        width: 100%;
        margin: 10px auto 20px auto;
        color: #696969;
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        max-width: 700px;
        display: flex;
        text-align: justify;
        text-align-last: center;
        justify-content: center;
        align-items: center;
    }

    .question-box strong {
        font-weight: 500;
        font-size: 15px;
        color: #6f6f6f;
    }

.answer-box .default {
    background: transparent;
    border: 1px solid #e2e2e2 !important;
    font-size: 14px;
    font-weight: 300;
    color: #555555;
    padding: 10px 0;
}

    .answer-box .default:hover {
        background: #f0f9f8;
    }

.result p {
    width: 100%;
    text-align-last: center;
    display: flex;
    line-height: 30px;
    text-align: justify;
    font-size: 16px;
    color: #8c8c8c;
    font-weight: 300;
    margin: 15px auto;
    max-width: 700px;
}

.result i {
}
/*------------------dashboard-------------*/
.dash-wrapper {
}
/*------------------yariway club----------*/
.card-club-slider {
    width: 100%;
    border: none;
    background: transparent;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
}

.left-pic-hold {
    width: 100%;
}

.card-club-slider .pic-hold {
    position: relative;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.club-slider-body h5 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 300;
}

.club-slider-body p {
    line-height: 30px;
    margin: 20px 0;
    color: #fff;
    font-weight: 300;
    font-size: 13px;
}

.club-slider {
    position: relative;
}

    .club-slider .owl-dots {
        position: absolute;
        bottom: 0;
        right: 30px;
    }

        .club-slider .owl-dots span {
            width: 13px !important;
            height: 13px !important;
            background: transparent !important;
            border: 1px solid #fff !important;
        }

        .club-slider .owl-dots .owl-dot.active span, .club-slider .owl-dots .owl-dot:hover span {
            background: #fff !important;
        }

.countdown-wrapper {
    display: flex;
    direction: ltr;
    justify-content: center;
}

    .countdown-wrapper p {
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 0;
        font-weight: 300;
        color: #fff;
    }

.time-sect {
    height: 65px;
    width: 60px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #fff;
    border-radius: 5px;
}

.time-remain {
    display: flex;
    padding: 10px 0;
    align-items: center;
}

    .time-remain span {
        font-size: 15px;
        display: inline-block;
        color: #8e8e8e;
        font-weight: 300;
    }

.count-down-section {
    background: url(../images/back-light.png), linear-gradient(90deg, rgb(250 175 73) 0%, rgb(251 175 73) 50%, #faaf49 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    min-height: 241px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
}

.count-down-section {
    text-align: center;
}

    .count-down-section h3 {
        font-size: 16px;
        color: #ffffff;
        font-weight: 400;
        margin: 20px 0;
    }

    .count-down-section .txt-festival {
        padding: 10px 20px;
        color: #ffffff;
        font-weight: 400;
        line-height: 30px;
        max-width: 600px;
        display: flex;
        margin: 0 auto 10px auto;
        font-size: 12px;
    }

.club-widget-card {
    cursor: pointer;
    width: 100%;
    border: none;
    border-radius: 10px;
    background: #fff;
    padding: 20px 10px;
    overflow: hidden;
    text-align: center;
    transition: transform 300ms;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
}

    .club-widget-card:hover {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(14, 38, 86, 0.09) !important;
    }

    .club-widget-card i {
        font-size: 40px;
        color: #379199;
        margin-bottom: 15px;
    }

    .club-widget-card p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 400;
        color: #424242;
    }
/*-------------------packages-------------*/

.pricing-section {
    position: relative;
    overflow: hidden;
    padding: 10px;
}

    .pricing-section .outer-box {
        max-width: 1100px;
        margin: 0 auto;
    }


    .pricing-section .row {
        margin: 0 -30px;
    }

.pricing-block {
    position: relative;
    margin-bottom: 40px;
}

    .pricing-block .inner-box {
        position: relative;
        background-color: #ffffff;
        box-shadow: 0 20px 40px rgba(0,0,0,0.08);
        max-width: 370px;
        margin: 0 auto;
        border-bottom: 5px solid #4baeb4;
        border-radius: 10px;
        overflow: hidden;
    }

    .pricing-block .icon-box {
        position: relative;
        padding: 20px 30px 0;
        background-color: #4baeb4;
        text-align: center;
    }

        .pricing-block .icon-box:before {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 75px;
            width: 100%;
            border-radius: 50% 50% 0 0;
            background-color: #ffffff;
            content: "";
        }


        .pricing-block .icon-box .icon-outer {
            position: relative;
            height: 150px;
            width: 150px;
            background-color: #ffffff;
            border-radius: 50%;
            margin: 0 auto;
            padding: 10px;
        }

        .pricing-block .icon-box i {
            position: relative;
            display: block;
            height: 130px;
            width: 130px;
            line-height: 120px;
            border: 5px solid #4baeb4;
            border-radius: 50%;
            font-size: 50px;
            color: #4baeb4;
            -webkit-transition: all 600ms ease;
            -ms-transition: all 600ms ease;
            -o-transition: all 600ms ease;
            -moz-transition: all 600ms ease;
            transition: all 600ms ease;
        }

    .pricing-block .inner-box:hover .icon-box i {
        transform: rotate(360deg);
    }

    .pricing-block .price-box {
        position: relative;
        text-align: center;
        padding: 10px 20px;
    }

    .pricing-block .title {
        position: relative;
        display: block;
        font-size: 14px;
        line-height: 1.2em;
        color: #4a4a4a;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .pricing-block .price {
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: #F44336 !important;
    }

    .pricing-block .features {
        position: relative;
        max-width: 240px;
        margin: 0 auto 20px;
    }

        .pricing-block .features li {
            position: relative;
            display: block;
            font-size: 12px;
            line-height: 30px;
            color: #484848;
            font-weight: 400;
            padding: 5px 0;
            padding-left: 20px;
            border-bottom: 1px dashed #dddddd;
        }

            .pricing-block .features li:before {
                position: absolute;
                left: 0;
                top: 50%;
                font-size: 16px;
                color: #4baeb4;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                content: "\f058";
                font-family: "Font Awesome 5 Free";
                margin-top: -8px;
            }

            .pricing-block .features li.false:before {
                color: #dc3545;
                content: "\f057";
            }

            .pricing-block .features li a {
                color: #848484;
            }

            .pricing-block .features li:last-child {
                border-bottom: 0;
            }

    .pricing-block .btn-box a:hover {
        color: #ffffff;
    }

    .pricing-block:nth-child(2) .icon-box i,
    .pricing-block:nth-child(2) .inner-box {
        border-color: #ffc107;
    }

    .pricing-block:nth-child(2) .btn-box a,
    .pricing-block:nth-child(2) .icon-box {
        background-color: #ffc107;
    }

        .pricing-block:nth-child(2) .icon-box i,
        .pricing-block:nth-child(2) .price {
            color: #ffc107;
        }

    .pricing-block:nth-child(3) .icon-box i,
    .pricing-block:nth-child(3) .inner-box {
        border-color: #4baeb4;
    }

    .pricing-block:nth-child(3) .btn-box a,
    .pricing-block:nth-child(3) .icon-box {
        background-color: #4baeb4;
    }

        .pricing-block:nth-child(3) .icon-box i,
        .pricing-block:nth-child(3) .price {
            color: #4baeb4;
        }

.current-point {
}

    .current-point h4 {
        font-size: 16px;
        font-weight: 300;
        color: white;
        background: #FFC107;
        display: flex;
        width: fit-content;
        margin: 15px auto;
        padding: 10px 20px;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgb(255 193 7 / 29%), 0 1px 3px rgb(255 193 7 / 22%);
    }

.sec-title h2 {
    font-size: 14px;
    margin: 26px 0;
    font-weight: 300;
    color: #363636;
    line-height: 28px;
}
/*---------------loading---------------*/
#loading-bar-spinner.spinner {
    -webkit-animation: loading-bar-spinner 600ms linear infinite;
    animation: loading-bar-spinner 600ms linear infinite;
    margin-bottom: 60px;
}

    #loading-bar-spinner.spinner .spinner-icon {
        width: 60px;
        height: 60px;
        border: solid 3px transparent;
        border-top-color: #4eb2b8 !important;
        border-left-color: #4eb2b8 !important;
        border-radius: 50%;
    }

@-webkit-keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-txt p {
    margin-bottom: 60px;
    font-size: 18px;
    color: #535353;
    font-weight: 200;
}

.spinner-wrapper img {
    width: 70px;
    height: 70px;
    margin-bottom: 60px;
}
/*--------------edits-----------*/
.btn-offline {
    width: 100%;
    background: #b6b6b6;
    box-shadow: 0 3px 6px rgb(182 182 182 / 38%), 0 1px 3px rgb(182 182 182 / 41%);
    padding: 5px 20px !important;
    line-height: 28px;
    border: none;
    display: inline-block;
    color: #fff !important;
    will-change: opacity;
    transition: opacity .3s;
    font-size: 13px;
    font-weight: 400;
    cursor: default !important;
}

    .btn-offline:focus, .btn-offline:active, .btn-offline:visited {
        box-shadow: 0 3px 6px rgb(182 182 182 / 38%), 0 1px 3px rgb(182 182 182 / 41%) !important;
    }

.Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: .7;
    transition: .3s ease;
    align-self: flex-start;
    position: absolute;
    left: 5px;
    top: 5px;
}

.Toastify__toast--success {
    background: #389ca4 !important;
}

.Toastify__toast--error {
    background: #dc3545 !important;
}

.Toastify__toast {
    font-size: 12px !important;
    padding: 8px 8px 8px 20px !important;
}

@media (max-width: 576px) {
    .mobile-responsive .doctor-image {
        width: 50px;
        height: 50px;
    }

    .mobile-responsive .parvane-no {
        margin-bottom: 0;
    }

    .mobile-responsive .btn-way-contact {
        font-size: 9px;
        color: #8b8b8b;
        font-weight: 500;
        border: none;
    }

    .mobile-responsive .btn {
        padding: 2px 10px !important;
    }

    .mobile-responsive .contact-ways-doctor {
        padding-top: 5px;
    }

    .mobile-responsive .rate-teaser, .mobile-responsive .cm-teaser {
        top: 1px;
        font-size: 11px;
    }

    .mobile-responsive .doctor-widget h2 {
        margin-top: 0;
    }

    .mobile-responsive .parvane-no p {
        font-size: 10px;
    }

    .doctor-single-page-plans {
        max-height: unset !important;
        height: unset !important;
    }
}

.nav-pills .nav-link {
    color: #818181;
}

.cons-id-num p {
    margin: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #6f6f6f;
}

.setting-wrapper .btn-success {
    color: #fff;
    background-color: #42a1a9;
    border: none;
    font-size: 12px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    transition-duration: 200ms;
}

    .setting-wrapper .btn-success:hover {
        background-color: #37878e;
    }

.setting-wrapper .btn-danger {
    font-size: 12px;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    color: #fff !important;
}

.swal2-title {
    color: #393939 !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.swal2-content {
    margin: 10px 0 !important;
    color: #545454 !important;
    font-size: 15px !important;
}

.swal2-styled {
    padding: 7px 15px !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.swal2-actions {
    margin: 15px auto 0 !important;
}

.swal2-content, .swal2-header {
    padding: 0 !important;
}

.live-conv {
}

    .live-conv .btn-start-consult {
        background: #3a969e;
        -webkit-animation: glowing 1500ms infinite;
        -moz-animation: glowing 1500ms infinite;
        -o-animation: glowing 1500ms infinite;
        animation: glowing 1500ms infinite;
    }

@keyframes glowing {
    0% {
        background-color: #3a969e;
        box-shadow: 0 0 3px #3a969e;
    }

    50% {
        background-color: #3a969e;
        box-shadow: 0 0 15px #3a969e;
    }

    100% {
        background-color: #3a969e;
        box-shadow: 0 0 3px #3a969e;
    }
}

.post-author .btn-delete-all {
    position: absolute;
    top: 5px;
    left: 5px;
}

.address-holder a {
    color: #155258;
    font-weight: 500;
}


.card-blog-home a.cm {
    cursor: default !important;
}

.card-blog-home a.like {
    cursor: default !important;
}

.card-blog-home a.bookmark {
    cursor: default !important;
}

.my-stock p {
    max-width: 180px;
    font-size: 11px;
    box-shadow: none !important;
    font-weight: 500;
    border-radius: 20px !important;
    display: flex;
    padding: 8px 5px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    border: 1px solid #ffffffcc !important;
    color: #ffffff !important;
    border: none;
    overflow: hidden !important;
    height: 35px;
    background: #3a969e;
}

.PhoneInput {
    background: transparent;
    width: 63px;
    position: relative;
    margin-right: 0;
    border: 1px solid #cbcbcb;
    height: 40px;
    border-radius: 5px 0 0 5px !important;
    border-right: none !important;
}

.CountryNumberPlusClass {
    margin-right: -65px;
    position: absolute;
    top: 12px;
    left: 51px;
}

.PhoneInputCountrySelect {
    position: absolute !important;
    top: 0 !important;
    left: -4px !important;
    height: 100% !important;
    min-width: 127px !important;
    z-index: 1 !important;
    border: 0 !important;
    opacity: 0 !important;
    cursor: pointer !important;
}

.PhoneInput #countryNumber {
    background: transparent !important;
    border: none;
    position: absolute;
    right: -80px;
    top: 0;
    bottom: 0;
    height: 40px;
    width: 60px;
    text-align: left;
    border-right: 1px solid #dfdfdf;
    direction: ltr;
}

.PhoneInput .PhoneInputCountryIcon {
    background: transparent !important;
    width: 40px !important;
    height: 40px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.PhoneInputCountrySelectArrow {
    display: none !important;
}

.have-cont-code {
    text-align: left;
    padding-left: 90px;
}

.phone-input-cont input.have-cont-code {
    border-radius: 0 5px 5px 0 !important;
    border-left: none !important;
}

.detail-it-in-archive {
    margin: 0;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #6f6f6f;
    padding: 5px;
    margin-bottom: 6px;
    background: #fcfcfc;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}

    .detail-it-in-archive p {
        margin: 0 !important;
    }

.decline-this-reserve {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 12px;
    margin-left: 5px;
}

.notifications-page li {
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.notifications-page ul {
    gap: 1rem !important;
}

.dashboard .dot.red {
    background: #dc3545;
    -webkit-animation: glowing-red 1500ms infinite;
    -moz-animation: glowing-red 1500ms infinite;
    -o-animation: glowing-red 1500ms infinite;
    animation: glowing-red 1500ms infinite;
}

@keyframes glowing-red {
    0% {
        background-color: #dc3545;
        box-shadow: 0 0 3px #dc3545;
    }

    50% {
        background-color: #dc3545;
        box-shadow: 0 0 15px #dc3545;
    }

    100% {
        background-color: #dc3545;
        box-shadow: 0 0 3px #dc3545;
    }
}

.login-container .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-right: 1.5rem;
    padding-left: unset !important;
}

.login-container .custom-control-label::before,
.custom-control-label::after {
    right: -1.5rem !important;
    left: unset !important;
    top: 8px !important;
}

.swal2-html-container {
    line-height: 30px;
    text-align: justify;
    text-align-last: center;
    font-size: 14px;
    white-space: pre-line;
}


div#swal2-content {
    white-space: pre-line;
}

.quickLinksWrap .doctor-single-page-plans {
    box-shadow: none;
}

button[data-tour-elem="right-arrow"] {
    margin-right: 24px !important;
    margin-left: 0 !important;
    transform: rotateY(180deg) !important;
}

button[data-tour-elem="left-arrow"] {
    margin-left: 24px !important;
    margin-right: 0 !important;
    transform: rotateY(180deg) !important;
}

div[data-tour-elem="controls"] {
    display: flex;
    align-items: baseline;
}

[aria-label="Close"] {
    top: 5px !important;
    right: 10px !important;
}

[data-tour-elem="badge"] {
    display: none !important;
}

.reactour__dot--is-active {
    background: #3a969e !important;
}

.reactour__helper p {
    text-align: justify;
}

.reactour__helper {
    max-width: 300px !important;
    padding: 20px !important;
}

.takhfif-holder input {
    font-size: 12px;
    font-family: 'iranyekan';
    font-weight: 500;
    text-align: right;
    width: 100%;
    margin: 0;
    padding: 0 7px 0 49px;
}

.takhfif-holder button {
    position: absolute;
    top: 8px;
    left: 5px;
    background: transparent;
    color: #4eb2b8 !important;
    border: 1px solid #4eb2b8;
    box-shadow: none;
    padding: 0 !important;
    max-width: 40px;
    font-size: 11px;
}

    .takhfif-holder button:hover {
        color: #fff !important;
        background: #4eb2b8 !important;
    }

.takhfif-holder div:first-child button {
    left: 13px;
}

.wallet-page .pay-card {
    max-height: unset !important;
}

.blur-it {
    filter: blur(1px);
    cursor: pointer;
}

    .blur-it input,
    .blur-it a,
    .blur-it button {
        pointer-events: none;
    }

.tooltip {
    line-height: 22px !important;
    font-size: 11px !important;
    font-weight: 300 !important;
    text-align: justify !important;
    text-align-last: center !important;
}

.btn-info-tooltip {
    font-size: 13px;
    color: #161616;
}

    .btn-info-tooltip i {
        margin-right: 5px;
        color: #787878;
        font-size: 10px;
    }

.in-reserve .form-group label {
    width: 100%;
}

.slick-prev, .slick-next {
    display: none !important;
}

.club-widget-card-yellow {
    background: #ffd555 !important;
}

    .club-widget-card-yellow:after {
        background: #ffd555 !important;
    }

    .club-widget-card-yellow p {
        color: #fff !important;
    }

.initial-meet {
    background: url(../images/bg-about.png), linear-gradient(90deg, rgb(203 228 230) 0%, rgb(255 255 255) 50%, rgb(203 228 230) 100%);
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    margin: 20px 0;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 11%), 0 3px 6px rgb(0 0 0 / 5%);
    padding: 30px 10px;
}

    .initial-meet h2 {
        color: #0a7c87;
        text-shadow: 1px 1px #17585a21;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .initial-meet p {
        color: #464646;
        display: block;
        font-weight: 300;
        font-size: 14px;
        line-height: 33px;
        max-width: 500px;
        margin: 0 auto;
        justify-content: center;
        margin-bottom: 10px;
    }

.PhoneInputCountryIconImg {
    display: block !important;
    width: 30px !important;
    height: 40px !important;
    margin: auto !important;
}

.info-modal i {
    font-size: 15px;
    background: #ffd400;
    width: 35px;
    height: 35px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    color: white;
}

.info-modal p {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 30px;
    text-align: center;
}

.info-modal {
    margin: 0;
    align-items: center;
    padding: 1rem;
}


.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
}









select.form-control.classic {
    background-color: white !important;
    padding: 0.5em 4em 0.5em 1em !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}


/* arrows */

select.form-control.classic {
    background-image: linear-gradient(45deg, transparent 50%, #004f56 50%), linear-gradient(135deg, #004f56 50%, transparent 50%), linear-gradient(to right, #4eb2b8, #4eb2b8) !important;
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
    background-size: 5px 5px, 5px 5px, 38px 40px !important;
    background-repeat: no-repeat !important;
}





.user-name {
    width: 100%;
    overflow: hidden;
    word-break: break-all;
    word-wrap: break-word;
}



.notif-name p {
    margin: 0;
    font-size: 11px;
    color: #979797;
    font-weight: 300;
    text-align: right;
    text-align-last: right;
}




.detail-it-in-archive p {
    margin: 0 !important;
    font-size: 11px;
    text-align: right;
    text-align-last: right;
    line-height: 20px;
}


.user-side {
    justify-content: right;
    text-align: right;
}



.archive-widget {
    padding: 8px;
}




    .archive-widget .action-icon.row.p-0.m-0.xxxxxxxxxxxxxxxxxxxxxxxx {
        margin-bottom: -10px !important;
    }



