
a#left-tabs-example-tab-first.active {
    color: #fff;
    background-color: #4eb2b8;
    box-shadow: 0 3px 6px rgba(78, 178, 184, 0.17), 0 1px 3px rgba(78, 178, 184, 0.29) !important;
}

a#left-tabs-example-tab-second.active {
    color: #fff;
    background-color: #f84455;
    box-shadow: 0 3px 6px rgb(220 53 69 / 16%), 0 1px 3px rgb(220 53 69 / 12%) !important;
}
