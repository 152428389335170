.side-bar {
    z-index: 999 !important;
    left: 0px !important;
}

.side-bar-veil {
    z-index: 999 !important;
}


.nav-link.code span, .nav-link.code.active span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    background: #39949c;
    border-radius: 5px;
    padding: 3px;
    margin-right: 5px;
    display: inline-block;
}

aside.ps-sidebar-root {
    z-index: 9999999 !important;
}



.Toastify__toast-theme--light {
    color: #ffffff !important;
}


.Toastify__toast {
    color: #ffffff !important;
}




.Toastify__progress-bar-theme--light {
    background: #fff !important;
}


.Toastify__progress-bar--bg {
    background: #fff !important;
}


.Toastify__toast-icon svg {
    fill: #fff !important;
}